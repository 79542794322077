// extracted by mini-css-extract-plugin
export var appointment_counter = "bookAppointment-module--appointment_counter--TMXQv";
export var box1 = "bookAppointment-module--box1--fr515";
export var box2 = "bookAppointment-module--box2--hP6Wy";
export var box3 = "bookAppointment-module--box3--VLv-W";
export var box4 = "bookAppointment-module--box4--oQECa";
export var box5 = "bookAppointment-module--box5---ewjg";
export var box6 = "bookAppointment-module--box6--6S7pF";
export var boxnumber = "bookAppointment-module--boxnumber--uBQOO";
export var button = "bookAppointment-module--button--JHiz8";
export var contact_doctors = "bookAppointment-module--contact_doctors--K2u6q";
export var form = "bookAppointment-module--form--7SAyr";
export var messenger_button = "bookAppointment-module--messenger_button--f-VW8";
export var request_appointment = "bookAppointment-module--request_appointment--h5zBF";
export var single_button = "bookAppointment-module--single_button--AaGh8";
export var small_text = "bookAppointment-module--small_text--V32Ru";
export var urgentcareneeds = "bookAppointment-module--urgentcareneeds--QfRXQ";