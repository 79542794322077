import React from "react"
import { Container, Col, Row, Button } from "react-bootstrap"
import { Link } from "gatsby-plugin-react-intl"

import { FaFacebookMessenger } from "react-icons/fa"
import * as styles from "../styles/components/bookAppointment.module.scss"

export default function BookAppointment({
  slug,
  templateLocation,
  textNumber,
  urgentCareUrl,
}) {
  return (
    <Container>
      <Row className="justify-content-center g-2">
        <Col className="d-flex justify-content-center text-center">
          <div className={`p-5 ${styles.box6} d-flex flex-column`}>
            <div className={`${styles.boxnumber} `}>1</div>
            <h2 className="my-5 py-5">Book Your Appointment Now</h2>

            <div className="">
              {templateLocation ? (
                <div className="my-5 d-flex flex-column">
                  <a href={templateLocation} target="_blank">
                    <Button className={`${styles.button} my-1`}>
                      Book Appointment
                    </Button>
                  </a>
                  {urgentCareUrl && (
                    <>
                      <a href={urgentCareUrl} target="_blank">
                        <Button className={`${styles.button} my-1`}>
                          Urgent Care
                        </Button>
                        <h5 className="mt-2">(Saturdays 8am to 4pm)</h5>
                      </a>
                    </>
                  )}
                </div>
              ) : (
                <Link to={`/locations/${slug}`}>
                  <Button className={`${styles.button} my-5`}>
                    <strong>Book Appointment</strong>
                  </Button>
                </Link>
              )}
            </div>
            <div className="mt-auto">
              <h6>
                Response time : <span>Fastest</span>
              </h6>
              <p>(Book online in a few minutes)</p>
            </div>
          </div>
        </Col>

        <Col className="d-flex justify-content-center text-center">
          <div className={`p-5 ${styles.box1}`}>
            <div className={` ${styles.boxnumber}`}>2</div>
            <h2 className="mt-5 pt-5">Request via Text</h2>
            <h5 className="mb-5 pb-3">for In-Office Appointment</h5>
            {textNumber ? (
              <div>
                <a href={`sms:${textNumber}`}>
                  <Button className={`my-2 ${styles.button}`}>
                    {textNumber}
                  </Button>
                </a>
              </div>
            ) : (
              <>
                <div>
                  <a href="sms:8186582701">
                    <Button className={` ${styles.button}`}>
                      English <span>818-658-2701</span>
                    </Button>
                  </a>
                </div>
                <div className="my-2">
                  <a href="sms:8184235637">
                    <Button className={` ${styles.button}`}>
                      Spanish <span>818-423-5637</span>
                    </Button>
                  </a>
                </div>
              </>
            )}
            <div>
              <a href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F141266270391%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink">
                <Button className={` ${styles.messenger_button}`}>
                  <FaFacebookMessenger className="me-2 mb-1" />
                  Send to Messenger
                </Button>
              </a>
            </div>
            <h6 className="pt-3 mt-5">
              Response time : <span>Fast</span>
            </h6>
            <p>(Usually within 10 min during office hours)</p>
          </div>
        </Col>

        <Col className="d-flex justify-content-center text-center">
          <div className={`p-5 ${styles.box2}`}>
            <div className={` ${styles.boxnumber}`}>3</div>
            <h2 className="mt-5 pt-5">Request Telemedicine</h2>
            <h5 className="mb-5 pb-3">May Include Video</h5>
            {textNumber ? (
              <div>
                <a href={`sms:${textNumber}`}>
                  <Button className={`my-2 ${styles.button}`}>
                    {textNumber}
                  </Button>
                </a>
              </div>
            ) : (
              <>
                <div>
                  <a href="sms:8186582701">
                    <Button className={` ${styles.button}`}>
                      English <span>818-658-2701</span>
                    </Button>
                  </a>
                </div>
                <div className="my-2">
                  <a href="sms:8184235637">
                    <Button className={` ${styles.button}`}>
                      Spanish <span>818-423-5637</span>
                    </Button>
                  </a>
                </div>
              </>
            )}
            <div>
              <a href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F141266270391%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink">
                <Button className={` ${styles.messenger_button}`}>
                  <FaFacebookMessenger className="me-2 mb-1" />
                  Send to Messenger
                </Button>
              </a>
            </div>
            <h6 className="pt-3 mt-5">
              <span>From Home</span>
            </h6>
            <p>(Usually within 30 min during office hours)</p>
          </div>
        </Col>

        <Col className="d-flex  justify-content-center text-center">
          <div className={`p-5 ${styles.box4}`}>
            <div className={` ${styles.boxnumber}`}>4</div>
            <h2 className="mt-5 pt-5">Request via Call</h2>

            <div className="my-5 pt-5 d-flex flex-column ">
              <a href="tel:8183615437">
                <Button className={`${styles.button} mb-3 mx-auto`}>
                  (818) 361-5437
                </Button>
              </a>

              <a href="tel:6267958811">
                <Button className={`${styles.button} mb-3 mx-auto`}>
                  (626) 795-8811
                </Button>
              </a>
              <a href="tel:8188830460">
                <Button className={`${styles.button} mb-3 mx-auto`}>
                  (818) 883-0460
                </Button>
              </a>
              <h6 className="pt-3 mt-3">
                Response time : <span>Fast</span>
              </h6>
              <p>You can call us anytime for an appointment</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
