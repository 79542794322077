import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import axios from "axios"
import { Link } from "gatsby-plugin-react-intl"
import { GatsbyImage } from "gatsby-plugin-image"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import { StaticImage } from "gatsby-plugin-image"
import Zoom from "react-medium-image-zoom"

//Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import TimeTable from "../components/time-table-location"
import BookAppointment from "../components/book-appointment"
import Title from "../components/title"

// Styles
import { Container, Row, Col, Button } from "react-bootstrap"
import "react-medium-image-zoom/dist/styles.css"
import * as styles from "../styles/templates/location.module.scss"

//Icons
import { GoStar } from "react-icons/go"
import { FaYelp } from "react-icons/fa"
import { FcGoogle } from "react-icons/fc"

export const query = graphql`
  query ($slug: String!) {
    contentfulLocation(slug: { eq: $slug }) {
      appointmentLink
      slug
      location
      urgentCareHours
      urgentCareUrl
      officeHours
      coordinates
      telehealth
      textNumber
      address {
        address
      }
      description {
        description
      }
      image {
        gatsbyImageData(
          quality: 100
          width: 600
          height: 400
          layout: FULL_WIDTH
        )
      }
      doctors {
        slug
        name
        specialization
        image {
          gatsbyImageData(
            quality: 100
            width: 300
            height: 300
            layout: CONSTRAINED
          )
        }
      }
      photos {
        gatsbyImageData(
          quality: 100
          placeholder: BLURRED
          width: 1920
          layout: CONSTRAINED
        )
      }
    }
  }
`

export default function Location({ data }) {
  const [reviews, setReviews] = useState(null)

  let names = []

  data.contentfulLocation.doctors?.map(doctor => {
    const firstName = doctor.name.split(" ")[0].toLowerCase()
    const lastName = doctor.name.split(" ")[1].replace(",", "").toLowerCase()
    const locationName = data.contentfulLocation.location.toLowerCase()

    names.push(firstName)
    names.push(lastName)
    names.push(locationName)
  })

  const contains = comment => {
    let includesName = false
    names.forEach(name => {
      if (comment.toLowerCase().includes(" " + name + " ")) {
        includesName = true
      }
    })

    return includesName
  }

  useEffect(() => {
    axios
      .post(
        "https://api.birdeye.com/resources/v1/review/businessId/165634118873631?&count=1000&api_key=CWFOSwxffcX6I2IgH1WvF9aWvB1hmauC",
        {}
      )
      .then(res => {
        return res.data.filter(review => {
          if (
            !review.comments ||
            review.rating < 4 ||
            review.sentimentType !== "POSITIVE"
          ) {
            return false
          }
          return contains(review.comments)
        })
      })
      .then(data => {
        setReviews(
          data.filter(function (el) {
            // Remove duplicate items
            if (!this[el.comments]) {
              this[el.comments] = true
              return true
            }
            return false
          }, Object.create(null))
        )
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  const containerStyle = {
    width: "100%",
    height: "280px",
  }

  const location = data.contentfulLocation
  const lat = parseFloat(location.coordinates.split(",")[0])
  const lng = parseFloat(location.coordinates.split(",")[1])
  const coordinates = { lat, lng }

  return (
    <Layout>
      <SEO
        title={location.location}
        description={location.description?.description}
      />

      <Title title={location.location} />
      <Container>
        <Row>
          <Col xs={12} xl={12} xxl={12} className="mt-5 mx-auto ">
            <Row className=" g-5">
              <Col md={6} className="px-5 py-5">
                <h1 className={`${styles.title} `}>{location.location}</h1>

                <a href="#map">
                  <h4 className={`mb-4 ${styles.address}`}>
                    <strong>
                      {location.address.address.split(/\r?\n/)[0]},
                      <br />
                      {location.address.address.split(/\r?\n/)[1]}
                    </strong>
                  </h4>
                </a>

                <p>{location.description?.description || location.location}</p>

                <h4 className="pt-3">
                  Text:{" "}
                  <a href={`sms:${location.textNumber}`}>
                    <strong>{location.textNumber}</strong>
                  </a>
                </h4>

                <h4 className="pt-3">
                  Call now:{" "}
                  {location.location === "West Hills" ? (
                    <a href="tel:8188830460">
                      <strong>(818) 883-0460</strong>
                    </a>
                  ) : location.location === "Santa Monica" ? (
                    <a href="tel:8188830460">
                      <strong>(818) 883-0460</strong>
                    </a>
                  ) : location.location === "Torrance" ? (
                    <a href="tel:310-540-8994">
                      <strong>310-540-8994</strong>
                    </a>
                  ) : location.location === "Pico Rivera" ? (
                    <a href="tel:562-776-3444">
                      <strong>562-776-3444</strong>
                    </a>
                  ) : location.location === "Mission Hills" ? (
                    <a href="818-921-4758">
                      <strong>818-921-4758</strong>
                    </a>
                  ) : location.location === "Arcadia" ? (
                    <>
                      <a href="tel:626-3582500">
                        <strong>626 358-2500</strong>
                      </a>
                      <br />
                      <br />
                      Fax:{" "}
                      <a href="fax:6263582311">
                        <strong>626 358-2311</strong>
                      </a>
                    </>
                  ) : location.location === "Downey" ? (
                    <a href="tel:562-904-2821">
                      <strong>562-904-2821</strong>
                    </a>
                  ) : location.location === "Tarzana" ? (
                    <a href="tel:818-708-8044">
                      <strong>818-708-8044</strong>
                    </a>
                  ) : location.location === "Culver City" ? (
                    <a href="tel:310-204-6897">
                      <strong>310-204-6897</strong>
                    </a>
                  ) : (
                    <>
                      <a href="tel:8183615437">
                        <strong>(818) 361-5437</strong>
                      </a>{" "}
                      or{" "}
                      <a href="tel:6267958811">
                        <strong>(626) 795-8811</strong>
                      </a>
                    </>
                  )}
                </h4>

                <div className="mt-5">
                  <LoadScript
                    googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
                  >
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={coordinates}
                      zoom={16}
                    >
                      {/* Child components, such as markers, info windows, etc. */}
                      <>
                        <Marker position={coordinates} />
                      </>
                    </GoogleMap>
                  </LoadScript>
                </div>
              </Col>

              <Col md={6} className="py-5">
                <TimeTable location={location} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div id="appointment" className="my-5">
        <BookAppointment
          id="appointment"
          templateLocation={location.appointmentLink}
          urgentCareUrl={location.urgentCareUrl}
          textNumber={location.textNumber}
        />
      </div>

      {/* Photo Gallery */}
      {location.photos && (
        <Container className="my-5">
          <Row>
            <Col xs={12} xl={12} xxl={12} className="mt-5 mx-auto">
              <h2 className={`text-center mb-5 ${styles.title}`}>
                Photo Gallery
              </h2>
              <Row xs={2} sm={3} lg={5} className="g-1 justify-content-center">
                {location.photos.map(image => {
                  return (
                    <Col>
                      <div
                        className={`${styles.photo} `}
                        style={{ height: "300px" }}
                      >
                        <Zoom>
                          <GatsbyImage
                            image={image.gatsbyImageData}
                            alt={image.title}
                            style={{ height: "300px" }}
                          />
                        </Zoom>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      )}

      {location.doctors && (
        <Container className="my-5 py-5">
          <h2 className={` text-center mb-2  ${styles.title}`} id="providers">
            <strong>Meet Our Providers At {location.location}</strong>
          </h2>
          <Row lg={3} className="g-5 text-center justify-content-center">
            {location.doctors?.map(doctor => {
              return (
                <Col>
                  <div
                    className={`${styles.doctor} text-center p-5`}
                    key={doctor.name}
                  >
                    <Link to={`/providers/${doctor.slug}`}>
                      {doctor.image ? (
                        <GatsbyImage
                          image={doctor.image.gatsbyImageData}
                          className={styles.img}
                          alt={doctor.name}
                        />
                      ) : (
                        <StaticImage
                          src="../images/placeholder.png"
                          alt="placeholder"
                          height={150}
                          width={150}
                          className={styles.img}
                        />
                      )}
                    </Link>
                    <h2 className={``}>{doctor.name}</h2>
                    {doctor.specialization && <p>{doctor.specialization}</p>}

                    <div
                      className={`d-flex flex-column flex-lg-row justify-content-center`}
                    >
                      <a href={location.appointmentLink} target="_blank">
                        <Button variant="book-now-pink2" className="m-1">
                          Book Now
                        </Button>
                      </a>
                      <Link to={`/providers/${doctor.slug}`}>
                        <Button variant="primary" className="m-1">
                          Read More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      )}

      {/* Testimonials */}
      {reviews?.length > 0 && (
        <Container className="my-5">
          <Col xs={12} xl={12} xxl={12} className="mt-5 mx-auto">
            <h2 className={`text-center mb-5 ${styles.title}`}>Testimonials</h2>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center mb-5">
              <a
                href="https://g.page/r/CUecCHG6KTsgEB0/review"
                target="_blank"
                className="me-lg-2 mb-2 mb-md-0"
              >
                <Button variant="tertiary" size="bg" className={styles.btn}>
                  Leave A Review on Google <FcGoogle />
                </Button>
              </a>

              <a
                href="https://www.yelp.com/writeareview/biz/WyYWZjBzdrmXVu0EExYkfQ?return_url=%2Fbiz%2FWyYWZjBzdrmXVu0EExYkfQ&review_origin=biz-details-war-button"
                target="_blank"
              >
                <Button variant="tertiary" size="bg" className={styles.btn2}>
                  Leave A Review on Yelp <FaYelp color="red" />
                </Button>
              </a>
            </div>
          </Col>

          <Row xs={1} sm={2} md={3} lg={4} className="justify-content-center">
            {data.contentfulLocation.slug == "northridge" ? (
              <Col className={``}>
                <div className={`${styles.review}  p-3`}>
                  <p className={`${styles.text} h3 my-5`}>
                    Dr. Hilma Benjamin is an incredible doctor. My daughter has
                    been with her since she was a baby. Always warm, friendly,
                    kind, and knowledgeable
                    <br />
                    We have been seeing Dr Benjamin since my first child was
                    born in 2015, amazing doctor we have built a wonderful
                    relationship now she sees my son as well. She is patient,
                    takes time and answers all questions and provides excellent
                    service. I'm the kind of parent that panics and each time I
                    went to her I left calm and in control! Even now in the
                    pandemic she kept me calm and collected with my son! I
                    recommend her highly! The office has great staff and
                    everything is clean and everyone is very helpful.
                  </p>
                  <div className="d-flex">
                    {
                      // Loop Star 5 times
                      <span className="h3">
                        <GoStar color="gold" />
                        <GoStar color="gold" />
                        <GoStar color="gold" />
                        <GoStar color="gold" />
                        <GoStar color="gold" />
                      </span>
                    }
                  </div>
                  <p className={`${styles.name} h5`}>
                    <strong>Armine C</strong>
                  </p>
                </div>
              </Col>
            ) : (
              ""
            )}

            {reviews?.map((review, i) => (
              <Col key={i} className={``}>
                <div className={`${styles.review}  p-3`}>
                  <p className={`${styles.text} h3 my-5`}>{review.comments}</p>
                  <div className="d-flex">
                    {
                      // Loop Star 5 times
                      Array(review.rating)
                        .fill(0)
                        .map((_, i) => (
                          <span className="h3" key={i}>
                            <GoStar color="gold" />
                          </span>
                        ))
                    }
                  </div>
                  <p className={`${styles.name} h5`}>
                    <strong>{`${review.reviewer.firstName} ${review.reviewer.lastName}`}</strong>
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </Layout>
  )
}
