import React from 'react'

import { Container, Row, Col, Button, Table } from 'react-bootstrap'
import {
  FaUserMd,
  FaClock,
  FaCalendar,
  FaCalendarDay,
  FaCalendarAlt
} from 'react-icons/fa'

import * as styles from '../styles/components/timetable.module.scss'
import { useIntl } from 'gatsby-plugin-react-intl'

const TimeTable = ({ location }) => {
  const intl = useIntl()

  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]
  return (
    <Container className='px-0'>
      <Col>
        <Table striped hover responsive className={`${styles.table} border`}>
          <thead>
            <tr>
              <th colSpan={3} className='p-5'>
                <h1 className='mb-0 d-flex align-items-center'>
                  <span className='small'>
                    <FaClock className='me-3' />
                  </span>
                  <strong>Opening Hours</strong>
                </h1>
              </th>
            </tr>
          </thead>
          <tbody>
            {days.map(day => {
              const dayExists = location.officeHours?.find(officeHour =>
                officeHour.split(':')[0].includes(day)
              )

              const telehealth = location.telehealth?.find(hour => {
                return hour.includes(day)
              })
              return (
                <tr className=''>
                  <td className='py-4 px-3 px-lg-5'>
                    {dayExists ? (
                      <p>
                        {
                          <>
                            <span className='d-flex align-items-center'>
                              <span>
                                <FaCalendarAlt
                                  className='me-3 d-none d-md-block'
                                  color='rgb(250, 156, 28)'
                                  style={{ fontSize: '12px' }}
                                />
                              </span>{' '}
                              {dayExists.split(': ')[0]}{' '}
                              {dayExists.split(': ')[1]}
                            </span>
                          </>
                        }
                      </p>
                    ) : (
                      <p>
                        {
                          <>
                            <span className='d-flex align-items-center'>
                              <FaCalendarDay
                                className='me-3 d-none d-md-block'
                                color='rgb(250, 156, 28)'
                                style={{ fontSize: '12px' }}
                              />{' '}
                              {day}
                            </span>
                            <span className={styles.orange}>
                              {intl.formatMessage({
                                id: 'telehealth'
                              })}{' '}
                              {/* <br className="d-md-none" /> */}
                              {telehealth?.split(`${day}: `)[1]}
                            </span>
                          </>
                        }
                      </p>
                    )}
                  </td>

                  <td className='text-end py-4 px-3 px-lg-5'>
                    {
                      // if Saturday and an urgent care location, link to urgentCareUrl, else appointmentLink
                      location.urgentCareUrl && day === 'Saturday' ? (
                        <a
                          href={location.urgentCareUrl}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Button variant='primary'>Book Now</Button>
                        </a>
                      ) : (
                        <a href={location.appointmentLink} target='_blank'>
                          <Button variant='primary'>Book Now</Button>
                        </a>
                      )
                    }
                  </td>
                </tr>
              )
            })}
          </tbody>
          <tfoot className={`${styles.footer}`}>
            <tr>
              <td colSpan={3} className='p-5'>
                <p>Telehealth Available Everyday!</p>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Col>

      {/* <Col xs={12} xl={12} xxl={12} className="mx-auto">
        <Row className={`${styles.container} py-5 px-3 px-lg-5`}>
          <Row className="py-5 align-items-center">
            <Col lg={9} className="">
              <p className="h3">
                <strong>
                  {location.location}
                </strong>
              </p>
              <h1 className="display-4 text-center text-md-start mb-3">
                <FaClock className="me-3" />
                <strong>
                  Opening Hours
                </strong>
              </h1>
              <a href={location.appointmentLink} >
                <h2 className={`${styles.blinking} mt-4`}><strong>Telehealth Available Everyday 9am - 8pm</strong></h2>
              </a>


            </Col>


            <Col lg={3} className="text-center text-md-start py-4">
              <Link to="/booknow"></Link>
            </Col>
          </Row>
          <Row>
            {days.map(day => {
              const dayExists = location.officeHours?.find(officeHour => officeHour.split(":")[0].includes(day))
              return (
                <Row
                  className={`py-5 gy-3 gy-md-0 text-center text-md-start border-bottom  border-white d-flex justify-content-between align-items-center `}
                >
                  <Col lg={9}>
                    <a href={location.appointmentLink} target="_blank" className="text-white d-flex h5">
                      <strong>
                        {dayExists ? <p>{dayExists}</p> : <p>{day}: Open for Telehealth 9am - 8pm</p>}
                      </strong>
                    </a>
                  </Col>

                  <Col lg={3} className="">
                    <a href={location.appointmentLink} target="_blank">
                      <Button variant="book-now" style={{ width: "100%" }}>
                        <FaUserMd className="me-2" /> Book Now
                      </Button>
                    </a>
                  </Col>
                </Row>
              )
            })}
          </Row>
          <p className="pt-5 h3"><strong>Call now: {location.location === "West Hills" ? <a className="text-white strong" href="tel:8188830460">(818) 883-0460</a> : <><a className="text-white strong" href="tel:8183615437">(818) 361-5437</a> or <a className="text-white strong" href="tel:6267958811">(626)795-8811</a></>} </strong></p>
        </Row>
      </Col> */}
    </Container>
  )
}

export default TimeTable
