// extracted by mini-css-extract-plugin
export var blinking = "timetable-module--blinking--c1q0H";
export var blinking2 = "timetable-module--blinking2--HqTPQ";
export var btnActive = "timetable-module--btnActive--TkCqn";
export var button = "timetable-module--button--NnEjC";
export var container = "timetable-module--container--bOB2P";
export var dark = "timetable-module--dark--8xXSR";
export var darkMobile = "timetable-module--darkMobile--oby9C";
export var days = "timetable-module--days--XN3w3";
export var footer = "timetable-module--footer--4Agdd";
export var green = "timetable-module--green--j29sA";
export var hours = "timetable-module--hours--k3++W";
export var item = "timetable-module--item--vpCWH";
export var location = "timetable-module--location--8U8g0";
export var locationList = "timetable-module--locationList--Ll5J9";
export var mainContent = "timetable-module--mainContent--f9ywf";
export var orange = "timetable-module--orange--HwtTP";
export var table = "timetable-module--table--lX1ZL";
export var tableRow = "timetable-module--tableRow--LeCuZ";
export var tele = "timetable-module--tele--RgC9T";
export var thead = "timetable-module--thead--pK0z-";