// extracted by mini-css-extract-plugin
export var address = "location-module--address--uc2Us";
export var btn2 = "location-module--btn2--fpjc8";
export var buttons = "location-module--buttons--a8MTg";
export var doctor = "location-module--doctor--Gud-8";
export var doctorWrapper = "location-module--doctorWrapper--k2s7v";
export var grid = "location-module--grid--exOlP";
export var heading = "location-module--heading--BiedH";
export var hours = "location-module--hours--FNL09";
export var img = "location-module--img--JwHcx";
export var name = "location-module--name--lDVsS";
export var review = "location-module--review--wRSrO";
export var text = "location-module--text--KUisN";
export var title = "location-module--title--btkM1";